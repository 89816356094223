import { Link } from 'gatsby';
import React, { ReactNode } from 'react';

import { ReactComponent as ChevronDuoRightSVG } from '../../../images/chevron_duo_right.svg';
import { ReactComponent as CircleRightSVG } from '../../../images/circle_right.svg';
import './lead.scss';

export enum leadOptions {
  arrow = 'arrow',
  animated = 'animated',
  duoChevron = 'duoChevron',
}

const Lead: React.FC<{
  slug?: string;
  url?: string;
  target?: string;
  style?: leadOptions;
  className?: string;
  children?: ReactNode;
}> = ({ children, slug, url, target, style, className }): JSX.Element => {
  const classList = [className];

  switch (style) {
    case 'animated':
      classList.push('animated');
      break;
    case 'duoChevron':
      classList.push('duo-chevron');
      break;
    default:
      classList.push('circle-arrow');
      break;
  }

  const renderIcon = () => {
    switch (style) {
      case 'animated':
        return <ChevronDuoRightSVG />;
      case 'duoChevron':
        return <ChevronDuoRightSVG />;
      default:
        return <CircleRightSVG />;
    }
  };

  const renderButton = (content: JSX.Element) => {
    if (slug) {
      return <Link to={slug}>{content}</Link>;
    } else {
      return (
        <a href={url} target={target ?? '_self'}>
          {content}
        </a>
      );
    }
  };

  return (
    <span className={classList.join(' ')}>
      {renderButton(
        <span>
          {children} {renderIcon()}
        </span>
      )}
    </span>
  );
};

export default Lead;
