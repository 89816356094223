import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import GlobalContext from '../../../context/global-context';
import { ArticleItem } from '../../../types/articleListPage';
import ArticleCard from '../../global/articleCard/articleCard';

import './articleList.scss';

type ArticleList = {
  list: Array<ArticleItem>;
};

const ArticleList: React.FC<{ list: Array<ArticleItem> }> = ({
  list,
}): JSX.Element => {
  const { i18n } = useContext(GlobalContext);

  const renderArticleList = (list: Array<ArticleItem>) => {
    if (!list.length) {
      return <div>{i18n?.t('news.not_found')}</div>;
    }

    return list.map((item) => {
      if (!item) return null;

      return (
        <Col lg={6} key={item.attributes.slug} className="gy-4">
          <ArticleCard block={false} data={item} />
        </Col>
      );
    });
  };
  return <Row className="article-list">{renderArticleList(list)}</Row>;
};

export default ArticleList;
