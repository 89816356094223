// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import React from 'react';

import Banner, { pageType } from '../components/global/banner/banner';
import Layout from '../components/global/layout/layout';
import { Paginations } from '../components/global/pagination/pagination';
import ArticleList from '../components/news/articleList/articleList';
import { PageBase } from '../context/global-context';
import { useLocaleFromRouter } from '../hooks/useLocaleFromRouter';
import { ArticleListPageContext } from '../types/articleListPage';
import { LocalizationData } from '../types/general';
import { PageData } from '../types/page';
import { getLocales, useI18n } from '../utils/i18n';
import { generateKey } from '../utils/keys';

const ArticleListPage: React.FC<{ pageContext: ArticleListPageContext }> = ({
  pageContext,
}): JSX.Element => {
  const { limit, currentPage, numPages, localesNumPages } = pageContext;

  const currentLocale = useLocaleFromRouter();
  const locales = getLocales([currentLocale]);
  const { translations } = pageContext;

  const localizationsData: LocalizationData[] = [];

  const i18n = useI18n(translations);

  locales.forEach((l) => {
    let slug = i18n.t('news.slug', l);
    slug = slug ? slug : 'news';

    const localeNumPages = localesNumPages.find((lnp) => lnp.locale === l);
    let localCurrentPage = currentPage;
    if (localeNumPages && localCurrentPage > localeNumPages.numPages)
      localCurrentPage = localeNumPages.numPages;

    localizationsData.push({
      attributes: {
        slug: `${slug}/${localCurrentPage}`,
        locale: l,
      },
    });
  });

  const instance: PageData = {
    attributes: {
      locale: currentLocale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <PageBase translations={translations} instance={instance}>
      <Layout>
        <Banner
          pageType={pageType.page}
          bannerType="common"
          title={i18n.t('news.title')}
        />
        <main>
          <ArticleList list={pageContext.list} />
          <Paginations
            id={generateKey('articles-pagination')}
            limit={limit}
            currentPage={currentPage}
            numPages={numPages}
          />
        </main>
      </Layout>
    </PageBase>
  );
};

export default ArticleListPage;
