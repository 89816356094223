import { navigate } from 'gatsby-link';
import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ArticleItem } from '../../../types/articleListPage';
import { getUploadUrl } from '../../../utils/general';
import { LocaleType } from '../../../utils/i18n';
import Lead, { leadOptions } from '../lead/lead';
import './articleCard.scss';

const ArticleCard: React.FC<{ block: boolean; data: ArticleItem }> = ({
  data,
  block,
}): JSX.Element => {
  if (typeof data === 'undefined') return <></>;

  const { attributes } = data;
  const { i18n } = useContext(GlobalContext);

  const currentLocale: LocaleType = useLocaleFromRouter();

  const leadStyle =
    block === true ? leadOptions.animated : leadOptions.duoChevron;

  let newsDetailsSlug = i18n?.t('news.details.slug');
  newsDetailsSlug = newsDetailsSlug ? newsDetailsSlug : 'news';

  const slug = `/${currentLocale}/${newsDetailsSlug}/${attributes.slug}`;

  return (
    <div className={block ? 'block-card-container' : 'article-card-container'}>
      {attributes.image?.data && (
        <img
          className="image"
          width={440}
          height={250}
          alt={attributes.image.data.attributes.alternativeText}
          src={getUploadUrl(attributes.image.data.attributes.url)}
          onClick={() => navigate(slug)}
        />
      )}

      <div className="date">
        {new Date(attributes.publishedAt).toLocaleDateString(
          attributes.locale,
          {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }
        )}
      </div>
      <h5 onClick={() => navigate(slug)}>{attributes.title}</h5>
      <p>{attributes.summary}</p>
      <Lead slug={slug} style={leadStyle}>
        {i18n?.t('read_more')}
      </Lead>
    </div>
  );
};

export default ArticleCard;
